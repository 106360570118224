.mobile-photo {
    position: relative;
    min-width: 150px;
    width: 150px;
    height: 100px;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        border: solid 0.5px rgba(0, 0, 0, 0.01);
    }

    &:hover:before {
        background-color: rgba(0, 0, 0, 0.12);
    }

    &__container {
        display: flex;
        margin-left: -4px;
        margin-right: -4px;

        > div:first-child {
            padding-left: 16px;
        }

        > div:last-child {
            padding-right: 16px;
        }
    }
}
