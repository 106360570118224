.icon-menu {
    background: url('../../assets/menu.svg') center no-repeat;
    background-size: contain;
    width: 18px;
    height: 14px;
}

.icon-explore {
    background: url('../../assets/map-24-px.svg') center no-repeat;
    background-size: contain;
    width: 21px;
    height: 21px;
}

.icon-mute {
    background: url('../../assets/mute-outline-28.svg') center no-repeat;
    background-size: contain;
    width: 28px;
    height: 28px;
}

.icon-cancel {
    background: url('../../assets/cancel.svg') center no-repeat;
    background-size: contain;
    width: 28px;
    height: 28px;
}

.icon-list {
    background: url('../../assets/article-outline-28.svg') center no-repeat;
    background-size: contain;
    width: 28px;
    height: 28px;
}

.icon-plus {
    background: url('../../assets/plus.svg') center no-repeat;
    background-size: contain;
    width: 18px;
    height: 14px;
}

.icon-minus {
    background: url('../../assets/minus.svg') center no-repeat;
    background-size: contain;
    width: 18px;
    height: 14px;
}

.icon-close {
    background: url('../../assets/close.svg') center no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}

.icon-time {
    background-image: url('../../assets/icon-time.svg');
}

.icon-ticket {
    background-image: url('../../assets/icon-ticket.svg');
}

.icon-phone {
    background-image: url('../../assets/icon-phone.svg');
}

.icon-message {
    background-image: url('../../assets/icon-message.svg');
}

.icon-globe {
    background-image: url('../../assets/icon-globe.svg');
}

.icon-place {
    background-image: url('../../assets/icon-place.svg');
}

.icon-move {
    background: url('../../assets/group-6.svg') center no-repeat;
    background-size: contain;
    width: 36px;
    height: 36px;
}

.icon-zoom {
    background: url('../../assets/icon-zoom.svg') center no-repeat;
    background-size: contain;
    width: 36px;
    height: 36px;
}

.icon-sound {
    background: url('../../assets/icon-sound.svg') center no-repeat;
    background-size: contain;
    width: 36px;
    height: 36px;
}

.icon-list-s {
    background: url('../../assets/group-9.svg') center no-repeat;
    background-size: contain;
    width: 36px;
    height: 36px;
}

.icon-compass {
    background: url('../../assets/map-24-px-2.svg') center no-repeat;
    background-size: contain;
    width: 36px;
    height: 36px;
}

.icon-info {
    background: url('../../assets/icon-info.svg') center no-repeat;
    background-size: contain;
    width: 36px;
    height: 36px;
}

.icon-volume {
    background: url('../../assets/volume-outline-28.svg') center no-repeat;
    background-size: contain;
    width: 28px;
    height: 28px;
}

.icon-vr {
    background: url('../../assets/vr-28.svg') center no-repeat;
    background-size: contain;
    width: 28px;
    height: 28px;
}

.icon-showui {
    background: url('../../assets/view-outline-28.svg') center no-repeat;
    background-size: contain;
    width: 28px;
    height: 28px;
}

.icon-hideui {
    background: url('../../assets/hide-outline-28.svg') center no-repeat;
    background-size: contain;
    width: 28px;
    height: 28px;
}

.icon-cancel-black {
    background: url('../../assets/cancel-outline-28-close.svg') center no-repeat;
    background-size: contain;
    width: 28px;
    height: 28px;
}

.icon-zoom-mobile {
    background: url('../../assets/zoom_24.svg') center no-repeat;
    background-size: contain;
    width: 28px;
    height: 28px;
}

.icon-hand {
    background: url('../../assets/hand.svg') center no-repeat;
    background-size: contain;
    width: 28px;
    height: 28px;
}

.icon-object {
    background: url('../../assets/search-outline-28.svg') center no-repeat;
    background-size: contain;
    width: 28px;
    height: 28px;
}

.icon-list-mobile {
    background: url('../../assets/list-outline-28.svg') center no-repeat;
    background-size: contain;
    width: 28px;
    height: 28px;
}
