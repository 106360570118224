.mobile-footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 16px 12px 16px;
    display: flex;
    justify-content: space-between;
    transition: all .2s ease-in-out;
    padding-bottom: 20px;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.5) 91%);
        height: 140px;
        width: 100%;
    }

    > * {
        position: relative;
    }

    .Button {
        border-radius: 50%;
        padding: 0;
        width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.2);

        &__in {
            width: 28px;
        }

        &__content {
            padding: 0 !important;
            display: flex;
            height: 52px !important;
            align-items: center;
            justify-content: center;
        }
    }

    .btn-tooltip {
        position: absolute;
        top: -38px;
        left: 16px;
        border-radius: 10px;
        padding: 7px 12px;
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.2);
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.17px;
        color: #ffffff;
        transition: 250ms;

        &-hide {
            opacity: 0;
        }
    }
}
