.map-block {
    position: absolute;
    border-radius: 8px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    transition: 250ms;

    &:hover {
        cursor: pointer;
    }

    &-dark {
        filter: invert(20%);
    }

    &-darken {
        filter: invert(80%);
    }

    &__indicator {
        width: 24px;
        height: 24px;
        background-image: linear-gradient(135deg, #626466, #444647);
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    &__center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 202px;
        height: 202px;
        background: #e1e3e6;
        border-radius: 50%;
        overflow: hidden;
    }
}
