.excursion-object {
    display: flex;
    flex-direction: column;
    width: 60px;
    margin-left: 7.5px;
    margin-right: 7.5px;
    margin-bottom: 7.5px;

    &__image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }

    &__title {
        margin-top: 8px;
        font-size: 12.5px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.28;
        letter-spacing: -0.14px;
        text-align: center;
        color: #828282;
        font-family: "TT Commons", sans-serif;
    }
}
