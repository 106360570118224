.excursion-window {
    position: absolute !important;
    left: 15px;
    bottom: 100%;
    width: 335px;
    border-radius: 12px;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.4);
    padding: 25px;
    box-sizing: border-box;
    z-index: 9;

    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: rgba(0, 0, 0, 0.8);

    animation-duration: 500ms;
    animation-name: showModal;
    animation-iteration-count: 1;

    &-hide {
        animation-duration: 500ms;
        animation-name: hideModal;
        animation-iteration-count: 1;
    }

    &__title {
        margin-bottom: 4px;
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: white;
        font-family: "TT Commons", sans-serif;
    }

    &__info {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.15px;
        color: white;
    }

    &__object {
        margin-top: 22px;
        border-top: 1px solid #ebebeb;
        padding-top: 17px;
    }

    &__subtitle {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.24px;
        color: #828282;
        text-transform: uppercase;
    }

    &__objects {
        display: flex;
        margin: 17px -7.5px -7.5px;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

@keyframes showModal {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes hideModal {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
