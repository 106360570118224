.location-new {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__container {
        margin-bottom: 20px;
        max-width: calc((100vw - 171px) / 3);
        width: 100%;
        box-sizing: border-box;
        display: flex;

        &:not(:last-child) {
            margin-right: 25px;
        }
    }

    &__header {
        position: relative;
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
    }

    &__image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 140px;
        border-radius: 8px;
    }

    &__angle {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 39px;
        height: 20px;
        border-radius: 4px;
        background-color: #ffffff;
        font-size: 11.5px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: 0.07px;
        text-align: right;
        color: #000000;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
    }

    &__name {
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: -0.32px;
        color: #ffffff;
    }

    &__description {
        margin-top: 8px;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: -0.08px;
        color: rgba(#ffffff, .7);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    &__info {
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        &-item {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }

        span, a {
            margin-left: 8px;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.54;
            letter-spacing: -0.08px;
            color: #ffffff;
            text-decoration: none;
        }
    }

    .icon {
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-position: center center;
        margin-top: 1.5px;
    }

    .icon-place-w {
        background-image: url('../../assets/icon-place-w.svg');
    }
    .icon-globe-w {
        background-image: url('../../assets/icon-globe-w.svg');
    }


    .Button__content {
        font-size: 12.5px;
    }

    &__footer {
        margin-top: 15px;

        .Button {
            width: 100%;
            border-radius: 4px;
            background: #fff;

            &:hover {
                opacity: .9;
            }

            &__content {
                font-size: 12.5px;
                color: #000;
                color: var(--text_primary);
            }
        }
    }
}
