.mobile-location {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    background: #fff;
    background: var(--background_content);
    border-radius: 10px;
    border: solid 0.5px rgba(#000c1a, .12);
    box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.01), 0 2px 4px 0 rgba(0, 0, 0, 0.01);

    &__item {
        display: flex;
        flex-direction: column;
        width: 235px;
        flex-grow: 1;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.01), 0 2px 4px 0 rgba(0, 0, 0, 0.01);

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    &__header {
        position: relative;
        height: 160px;
    }

    &__image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: calc(100% + 1px);
        height: 100%;
        position: relative;
        margin-left: -1px;
        margin-top: -1px;
        border-radius: 10px 10px 0 0;
    }

    &__angle {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 44px;
        height: 22px;
        border-radius: 6px;
        background-color: rgba(0, 0, 0, 0.5);
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.08px;
        text-align: center;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__content {
        padding: 12px 16px 20px;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__title {
        font-family: "SF Pro Text";
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: -0.34px;
        color: black;
    }

    &__description {
        margin-top: 5px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.15px;
        color: rgba(0, 0, 0, .75);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    }

    &__info {
        margin-top: 16px;
        display: flex;
        flex-direction: column;

        &-item {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 12px;
            }

            .icon {
                width: 15px;
                height: 19px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }

            .icon-place-b {
                background-image: url('../../assets/icon-place-b.svg');
            }
            .icon-globe-b {
                background-image: url('../../assets/icon-globe-b.svg');
            }
        }

        span, a {
            margin-left: 8px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.15px;
            color: #4986cc;
            text-decoration: none;
        }
    }

    &__footer {
        padding: 0 16px 16px;

        .Button {
            border-radius: 10px;
            background: var(--accent) !important;
            color: white !important;

            &:hover {
                opacity: .9;
            }
        }
    }
}
