.mobile-page {
    .Panel__in {
        background: url(../../assets/app.jpg) top center no-repeat !important;
        background-size: cover !important;
    }

    .logo {
        margin: 62px auto 10px;
        display: block;
    }

    &__head {
        padding: calc(70px + var(--safe-area-inset-top)) 16px 20px;
        display: flex;
        flex-direction: column;
    }

    &__title {
        font-family: TT Commons;
        font-size: 28px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
    }

    &__subtitle {
        margin-top: 10px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: -0.32px;
        color: rgba(#fff, .7);
        text-align: center;
    }
}
