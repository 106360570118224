.location-content {
    padding: 16px;
    display: flex;
    flex-direction: column;

    &__name {
        font-family: 'SF Pro Text', serif;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.24px;
        color: #000000;
    }

    &__description {
        font-family: 'SF Pro Text', serif;
        margin-top: 3px;
        margin-bottom: 13px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.15px;
        color: #828282;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .Button {
        border-radius: 4px !important;
    }

    .Button__content {
        font-size: 12.5px;
        font-weight: normal;
    }
}
