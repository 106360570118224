.mobile-modal {
    &__open {
        overflow: hidden;

        &__footer {
            transform: translateY(100%);
        }

        .modal__wrap {
            transform: translateY(0);
        }
    }

    &__overlay {
        position: fixed;
        z-index: 20;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.79) 71%, rgba(0, 0, 0, 0.8) 91%);
        overflow: auto;

        animation-duration: 1s;
        animation-name: showModal;
        animation-iteration-count: 1;

        &-hide {
            animation-duration: 500ms;
            animation-name: hideModal;
            animation-iteration-count: 1;
        }
    }

    &__wrap {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        transition: all .5s ease-in-out;
    }

    &__content {
        padding: 15px;
        margin: 0 auto;
    }

    &__page {
        box-sizing: border-box;
        z-index: 20;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }

    &__block {
        position: relative;
        border-radius: 12px;
        background-color: #fff;
        padding: 25px;
        box-sizing: border-box;
    }
}

@keyframes showModal {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes hideModal {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

