.mobile-about {

    .btn-back {
        position: fixed;
        top: calc(11px + var(--safe-area-inset-top));
        left: 11px;
    }

    &__image {
        width: 100%;
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    &__header {
        position: absolute;
    }

    &__content {
        background: #fff;
        background: var(--background_content);
    }

    &__wrapp {
        display: flex;
    }

    &__title {
        padding: 16px 16px 0;
        font-size: 22px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.32px;
        color: #000000;
        color: var(--text_primary);
    }

    &__description {
        margin-top: 4px;
        margin-bottom: 18px;
        padding-left: 16px;
        padding-right: 16px;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.3px;
        color: #000000;
        color: var(--text_primary);
    }

    &__subtitle {
        height: 40px;
        padding-top: 14px;
        padding-bottom: 10px;
        box-sizing: border-box;
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: -0.08px;
        color: #909499;
        color: var(--text_secondary);
        text-transform: uppercase;
    }

    .Separator__in {
        margin-left: 0!important;
        margin-right: 0!important;
    }

    &__photo {
        display: flex;
        margin-left: -4px;
        margin-right: -4px;

        > div:first-child {
            padding-left: 16px;
        }

        > div:last-child {
            padding-right: 16px;
        }

        &__item {
            position: relative;
            min-width: 150px;
            width: 150px;
            height: 100px;
            margin-left: 4px;
            margin-right: 4px;
            border-radius: 10px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            overflow: hidden;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 10px;
                border: solid 0.5px rgba(0, 0, 0, 0.01);
            }

            &:hover:before {
                background-color: rgba(0, 0, 0, 0.12);
            }
        }
    }

    &__block-map {
        margin-top: 24px;
        padding-left: 16px;
        padding-right: 16px;
    }

    &__map {
        position: relative;
        margin-top: 4px;
        width: 100%;
        height: 140px;
        border-radius: 10px;
        background-color: #eee;

        > * {
            position: relative;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            border: solid 0.5px rgba(0, 0, 0, 0.08);
        }

        &-text {
            margin-top: 8px;
            padding-top: 8px;
            padding-bottom: 8px;
            display: flex;

            span {
                margin-left: 12px;
                display: block;
                font-size: 15px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: -0.17px;
                color: #3f8ae0;
                color: var(--accent);
            }
        }
    }

    &__info {
        margin-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        flex-direction: column;

        &__item {
            display: flex;
            align-items: center;
            padding-top: 8px;
            padding-bottom: 8px;

            a {
                color: #3f8ae0;
                color: var(--accent);
                text-decoration: none;
            }
        }

        &__text {
            margin-left: 12px;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.34;
            letter-spacing: -0.17px;
            color: #000000;
            color: var(--text_primary);

            p {
                margin: 0;
            }
        }
    }

    &__icon {
        width: 20px;
        min-width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    &__footer {
        padding: 16px;
        background: var(--background_content);
        z-index: 10;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 1px;
            background: #d7d8d9;
            background: var(--separator_common);
            -webkit-transform-origin: center top;
            transform-origin: center top;
            -webkit-transform: scaleY(.5);
            transform: scaleY(.5);
        }
    }
}
