.mobile-instruction {
    position: absolute;
    box-sizing: border-box;
    padding: 12px 16px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    bottom: 0;
    width: 100%;

    &__title {
        font-family: "TT Commons", serif;
        font-size: 32px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #ffffff;
    }

    &__content {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
    }

    &__item {
        display: flex;
        align-items: center;

        & + & {
            margin-top: 12px;
        }

        [class^="icon-"] {
            border-radius: 10px;
            backdrop-filter: blur(10px);
            background-color: rgba(255, 255, 255, 0.2);
            width: 44px;
            height: 44px;
            background-size: 24px;
        }

        span {
            margin-left: 12px;
            font-size: 17px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.41px;
            color: #ffffff;
        }
    }

    .excursion--menu-footer {
        margin-top: 24px;
    }
}
