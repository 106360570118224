.mobile-info {
    &__description {
        box-sizing: border-box;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 16px;
        padding-right: 16px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &__title {
        font-family: "TT Commons";
        font-size: 32px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
    }

    &__info {
        margin-top: 2px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: -0.32px;
        color: #ffffff;
    }

    &__object {
        margin-top: 28px;
        border-top: 1px solid rgba(255, 255, 255, 0.24);
        margin-left: -16px;
        margin-right: -16px;
    }

    &__subtitle {
        padding: 14px 16px 10px;
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.08px;
        color: rgba(#fff, .7);
        text-transform: uppercase;
    }

    &__objects {
        display: flex;
        margin-top: 4px;
        margin-left: -12px;
        margin-right: -12px;

        > div:first-child {
            padding-left: 16px;
        }

        > div:last-child {
            padding-right: 16px;

            .object--item {
                margin-right: 0;
            }
        }
    }

    &__footer {
        margin-top: 38px;
        display: flex;
        justify-content: center;
        padding-bottom: 8px;

        .Button {
            background: #fff;
            border-radius: 50%;
            width: 56px;
            height: 56px;
            padding: 0;
        }
    }
}


.mobile-photo {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-right: 12px;

    &__image {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }

    &__name {
        margin-top: 8px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.15px;
        text-align: center;
        color: rgba(#fff, .7);
    }
}
