.mobile-menu {
    &__item {
        .ActionSheetItem__children {
            color: white;
        }

        &::before {
            -webkit-backdrop-filter: blur(12px) !important;
            backdrop-filter: blur(12px) !important;
            background-color: rgba(54, 54, 54, 0.74) !important;
        }

        &-last {
            border-bottom-left-radius: 14px !important;
            border-bottom-right-radius: 14px !important;
        }
    }

    &__back {
        padding: 7px 16px;
        color: #ffffff;
        font-size: 14px;
        font-weight: 500;
        -webkit-backdrop-filter: blur(12px);
        backdrop-filter: blur(12px);
        background-color: rgba(40, 43, 46, 0.2);
        border-radius: 10px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.15px;
    }

    &__button {
        padding: 4px 10px;
        color: #ffffff;
        font-size: 14px;
        border-radius: 10px;
        -webkit-backdrop-filter: blur(12px);
        backdrop-filter: blur(12px);
        background-color: rgba(40, 43, 46, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
    }

    &__buttons {
        position: absolute;
        top: calc(5px + var(--safe-area-inset-top));
    }
}
