.mobile-navigation {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    overflow: auto;
    position: absolute;
    bottom: 0;

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &__map {
        position: relative;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    &__buttons {
        margin-top: var(--safe-area-inset-top);
    }

    .tabs-inner {
        padding-bottom: 24px;
        overflow: auto;
    }
}

.mobile-tabs-header {
    position: relative;
    padding-top: 24px;
    padding-left: 16px;
    padding-right: 16px;
    background: transparent;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.2);
        -webkit-transform-origin: center top;
        transform-origin: center top;
        -webkit-transform: scaleY(.5);
        transform: scaleY(.5);
    }

    .TabsItem {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.15px;
        text-align: center;
        color: #fff;
        border-radius: 10px;
        border: solid 1px #ffffff;
        padding: 0;
        flex-grow: 0;

        &--selected {
            background: #fff;
            color: #000;
        }

        &:after {
            display: none;
        }
    }
}
