.excursion {
	position: relative;
	// min-height: 645px;
	overflow: hidden;

	&__mask {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 0 0;
		width: 100%;
		height: 100%;
		position: absolute;
	}

	&__top {
		position: absolute;
		top: 15px;
		left: 15px;
		display: flex;

		&-left {
			margin-left: 8px !important;
		}
	}

	&__zoom {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 15px;
		display: flex;
		flex-direction: column;
		background-color: rgba(0, 0, 0, 0.7);
		border-radius: 10px;

		.btn {
			width: 44px;
			height: 44px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

		.sep {
			width: 100%;
			height: 1px;
			background: rgba(255, 255, 255, 0.2);
		}
	}

	&__footer {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 12px 12px 22px 12px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
			height: 150px;
			width: 100%;
		}

		> * {
			position: relative;
		}

		.fixed-width {
			width: 230px !important;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.Button__content {
			color: #fff;
			line-height: 18px;
		}

		[class^="icon-"] {
			margin-right: 8px;
		}
	}

	.btn-round {

		span {
			pointer-events: none;
			width: 0;
			opacity: 0;
			font-size: 13px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: -0.08px;
			color: #222222;
		}

		&:hover {
			width: auto;

			span {
				pointer-events: all;
				margin-left: 4px;
				margin-right: 11px;
				width: auto;
				opacity: 1;
			}
		}
	}

	.btn-return {
		position: absolute;
		left: 25px;
		bottom: 133px;
	}

	.btn-search {
		position: absolute;
	}

	.i-search {
		display: flex;
		align-items: center;
	}

	&__menu {
		position: absolute;
		top: calc(100% + 5px);
		left: calc(100% - 52px);
		margin: 0;
		padding: 6px 0;
		border-radius: 8px;
		box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.3);
		background-color: #fff;
		display: flex;
		flex-direction: column;

		.Button {
			padding: 0 12px;
			background: var(--button_tertiary_background);
			border-radius: 0;

			&:hover {
				background: rgba(#76787A, .2);
			}

			&__in {
				justify-content: flex-start;
			}

			&__content {
				white-space: nowrap;
				font-size: 13px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.38;
				letter-spacing: -0.14px;
				color: #000000;
			}
		}
	}
}

.btn-bg {
	border-radius: 4px !important;
	background-color: rgba(0, 0, 0, 0.7) !important;

	.Button__content {
		line-height: 18px;
	}
}
