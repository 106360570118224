.page-new {
    &__background {
        height: 100vh;
        width: 100%;
        background-image: url("../../assets/app.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        margin-top: 75px;
        text-align: center;
        font-family: "TT Commons", sans-serif;
        font-size: 30px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        color: white;
    }

    &__subtitle {
        margin-top: 13px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: -0.32px;
        color: rgba(255, 255, 255, .7);
        text-align: center;
        margin-bottom: 67px;
    }
}
